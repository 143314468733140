<template>
    <div class="partner-area">
        <div class="container">
            <h3>More than 1.5 million businesses and organizations use DOOH advertisements to promote their sales</h3>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Partner'
    }
</script>