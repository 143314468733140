<template>
    <div>
        <div class="about-area ptb-70">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-content">
                            <span>How we were founded</span>
                            <h2>Company Overview</h2>
                            <p>Dibasys is an organization of Indian origin where the synergy between Information Technology and Digital Media is evident.</p>
                            <p>We pride in our work and constantly strives to exceed client's expectations. Our solutions and platforms exhibit exceptional quality and are endorsed by business across the globe.</p>
                            <p>Our mission is to assist clients in saving time and money.</p>
                        </div>
                        <br>
                        <router-link to="/contact" class="btn btn-primary">
                            Talk to us
                        </router-link>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="about-image">
                            <img :src="aboutSubImg" alt="image">
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
export default {
    name: 'About',
    data (){
        return {
            isPopup: false,
            aboutSubImg: `${cdnUrl}/dibasys/about-img1.jpg`,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>