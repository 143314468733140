<template>
    <div class="payment-experience-area ptb-70" style=" background-color: #F4F7F9;">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="payment-experience-content">
                        <h2>We made payment easy and secure</h2>
                        <p>We take our clients payment details very seriously. So we have integrated our system with the world-known payment gateway - Stripe. 
                            This dynamic partnership brings forth a new era of transactional excellence, ensuring that your payment experiences are not just secure but effortlessly smooth. 
                            With Stripe's advanced security infrastructure, your financial data is shielded with layers of encryption, fraud detection, and authentication protocols. 
                            Rest easy, knowing that every transaction is fortified against potential threats.
                        </p>
                        <p>Our integration with Stripe brings you an intuitive, user-friendly interface that simplifies the checkout process for your customers. 
                            From one-click payments to subscription management, we've streamlined every interaction to enhance user satisfaction.
                        </p>
                        <p>
                            All payment related items - invoices, billing status and advertising expenses can be monitored via our platform, 
                            which makes it easier for your accounts department to make sure that you are within budget.
                        </p>
                        <router-link to="/register" class="link-btn">
                            Sign Up Now
                        </router-link>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="payment-experience-image text-center">
                        <img :src="pricingSub1" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
    export default {
        name: 'PricingDetails',
        data (){
        return {
            pricingSub1: `${cdnUrl}/dibasys/experience-img1.png`,
        }
    },
    }
</script>
