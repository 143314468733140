<template>
    <div class="account-create-area">
        <div class="container">
            <div class="account-create-content">
                <h2>Start your free trial with us</h2>
                <p>Get your dibasys account today!</p>
                <router-link to="/register" class="btn btn-primary">
                    Free Trial
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AccountCreate'
    }
</script>