<template>
    <div class="features-section ptb-70 bg-f7fafd">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="features-box-list">
                        <div class="row">
                            <div class="col-lg-12 col-sm-6 col-md-6">
                                <div class="features-box">
                                    <div class="icon">
                                        <i class="flaticon-settings"></i>
                                    </div>

                                    <h3>Assign Roles & Permissions</h3>
                                    <p>Categorize the users with roles and manage what they can do by assigning various permissions.</p>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-6 col-md-6">
                                <div class="features-box">
                                    <div class="icon bg-f78acb">
                                        <i class="flaticon-envelope-of-white-paper"></i>
                                    </div>

                                    <h3>Device Activity Timeline</h3>
                                    <p>Preview last three activities of each device and their status using the timeline feature we have.</p>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-6 col-md-6">
                                <div class="features-box">
                                    <div class="icon bg-cdf1d8">
                                        <i class="flaticon-menu"></i>
                                    </div>

                                    <h3>In-House Support</h3>
                                    <p>In case, if something didn't go as expected, support tickets can be raised within our platform.</p>
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-6 col-md-6">
                                <div class="features-box">
                                    <div class="icon bg-c679e3">
                                        <i class="flaticon-info"></i>
                                    </div>

                                    <h3>Highly Customised Dashboard</h3>
                                    <p>Know everything you want about your advertisements at a glance from our customised dashboard.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <div class="features-image">
                        <img :src="featuresSub4" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
    export default {
        name: 'FeatureTwo',
        data (){
        return {
            featuresSub4: `${cdnUrl}/dibasys/features-img1.png`,
        }
    },
    }
</script>