<template>
    <div>
        <Navbar />
        <PageTitle />
        <ProductDetails />
        <HowItWorks />
        <AccountCreate />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../layout/Navbar'
import PageTitle from '../products/PageTitle.vue'
import ProductDetails from '../products/ProductDetails.vue'
import HowItWorks from '../products/HowItWorks.vue'
import AccountCreate from '../common/AccountCreate'
import Footer from '../layout/Footer'

export default {
    name: 'Products',
    components: {
        Navbar,
        PageTitle,
        ProductDetails,
        HowItWorks,
        AccountCreate,
        Footer,
    }
}
</script>