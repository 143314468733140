<template>
    <div class="ctp-choose-area pb-100">
        <div class="container-fluid">
            <div class="row justify-content-center m-0">
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="ctp-choose-image jarallax" data-jarallax='{"speed": 0.3}'></div>
                </div>

                <div class="col-lg-6 col-md-12 p-0">
                    <div class="ctp-choose-content">
                        <h3>Why Choose <font class="logo">dibasys</font></h3>
                        <p>Dibasys - A burgeoning domain delivering myriad of features for brands and partners to explore innovative advertisement strategies in the DOOH- space</p>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="flaticon-check-mark"></i>
                                </div>
                                Quality Driven
                            </h4>
                            <p>In the ever-evolving landscape of DOOH, the pursuit of excellence is not just a goal; it's a continuous journey. 
                                At the heart of this journey lies a commitment to quality that transcends conventional standards. 
                                A quality-driven mindset isn't just about meeting expectations; it's about surpassing them, setting new benchmarks, and redefining what is possible - 
                                that makes dibasys unique.
                            </p>
                        </div>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="flaticon-check-mark"></i>
                                </div>
                                Client Centred
                            </h4>
                            <p>
                                Our compass points to one cardinal direction—towards our clients. We don't just consider our clients as partners; 
                                we consider them the heartbeat of our company. The journey we embark on is not solely ours; 
                                it's a shared odyssey where client success is not a destination but the very path we tread.
                            </p>
                        </div>
                        <div class="choose-inner-card">
                            <h4>
                                <div class="icon">
                                    <i class="flaticon-check-mark"></i>
                                </div>
                                Cost Effective
                            </h4>
                            <p>
                                In a world where efficiency and value reign supreme, the pursuit of excellence doesn't have to come with a hefty price tag.
                                At dibasys, we have mastered the art of being not just cost-conscious but cost-effective, 
                                ensuring that every endeavour is a testament to both quality and economic prudence.
                            </p>
                            <br>
                            <p>
                                Being cost-effective is not about cutting corners; it's about sharpening our focus on what truly matters. 
                                It's a commitment to extracting maximum value from every resource, optimizing processes, and embracing innovation that streamlines without compromise.
                            </p>
                        </div>
                        <br>
                        <router-link to="/features" class="btn btn-primary">
                            See all features
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'WhyChoose'
}
</script>
