<template>
    <div :class="['navbar-area navbar-style-two', {'is-sticky': isSticky}]">
        <div class="vibion-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <router-link class="navbar-brand" to="/">
                        <p class="dbs-logo">dibasys</p>
                    </router-link>

                    <div 
                        class="navbar-toggler"
                        @click="active = !active" :aria-pressed="active ? 'true' : 'false'"
                        v-bind:class="{ 'active': button_active_state }"
                        v-on:click="button_active_state = !button_active_state"
                    >
                        <span class="icon-bar top-bar"></span>
                        <span class="icon-bar middle-bar"></span>
                        <span class="icon-bar bottom-bar"></span>
                    </div>

                    <b-collapse class="collapse navbar-collapse" :class="{ show: active }" is-nav>
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item">
                                <router-link to="/" class="nav-link">
                                    Home
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/about-us" class="nav-link">
                                    About Us
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/products" class="nav-link">
                                    Products
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/features" class="nav-link">
                                    Features
                                </router-link>
                            </li>
 
                            <li class="nav-item">
                                <a href="#" class="nav-link">
                                    Resources <i class="fas fa-chevron-down"></i>
                                </a>

                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <router-link to="/privacy-policy" class="nav-link">
                                            Privacy Policy
                                        </router-link>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <router-link to="/pricing" class="nav-link">
                                    Pricing
                                </router-link>
                            </li>
                            <li class="nav-item">
                                <router-link to="/contact" class="nav-link">
                                    Contact
                                </router-link>
                            </li>
                        </ul>
                         <div class="others-options d-flex align-items-center" id="t">
                        <div class="others-options">
                            <div class="k">
                            <router-link @click="toLoginPage()" to="/" class="login-btn" id="login-btn">
                                <i class="flaticon-user"></i> LogIn
                            </router-link>
                            </div>
                        </div>
                        <div class="options-item">
                            <router-link to="/register" class="btn btn-primary" id="registr-btn">Get Started</router-link>
                        </div>
                    </div>
                    </b-collapse>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'NavbarStyleTwo',
        data(){
            return {
                isSticky: false,
                active: false,
                button_active_state: false
            }
        },

        mounted(){
            const that = this
            window.addEventListener('scroll', () => {
                let scrollPos = window.scrollY
                if(scrollPos >= 100){
                    that.isSticky = true
                } else {
                    that.isSticky = false
                }
            })
        },
        methods: {
            toLoginPage() {
                window.open('https://dooh.dibasys.com/', '_blank');
            },
        }
    }
</script>
<style scoped>
#login-btn {
    margin-right: 15px;
    margin-left: 20px;
}
#registr-btn {
    margin-right: 20px;
}
</style>