<template>
    <div class="feedback-area ptb-70 bg-f7fafd">
        <div class="container">
            <div class="section-title">
                <h2>What customers say about Us</h2>
                <div class="bar"></div>
            </div>

            <div class="feedback-slides">
                <carousel 
                    :autoplay="5000"
                    :settings="settings"
                    :wrap-around="true"
                    :breakpoints="breakpoints"
                >
                    <slide v-for="slide in carouselItems" :key="slide.id">
                        <div class="single-feedback">
                            <div class="client-img">
                                <img :src="slide.image" alt="image">
                            </div>

                            <h3>{{slide.name}}</h3>
                            <span>{{slide.position}}</span>
                            <p>{{slide.description}}</p>
                        </div>
                    </slide>

                    <template #addons>
                        <Navigation />
                    </template>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'ManagePortfolio',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    data: () => ({
        carouselItems: [
            {
                id: 1,
                image: require('../../assets/images/clients/account-avatar.png'),
                name: 'Fr. Augustine',
                position: 'St Bernads Botany, Sydney',
                description: 'DBSystems give ultimate priority to their customers. Their product and service quality are very rare these days. I found very easy to use their software suite.',
            },
            {
                id: 2,
                image: require('../../assets/images/clients/account-avatar.png'),
                name: 'Marin',
                position: 'CEO',
                description: 'Swift Signage is very easy to use and they offers market leading features.',
            },
            {
                id: 3,
                image: require('../../assets/images/clients/account-avatar.png'),
                name: 'Sarah Albert',
                position: 'Marketing Assistant',
                description: 'A bunch of professional gems - DBS Team. Will continue to work with them to accomplish our future sales campaign.',
            },
        ],
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
    }),
})
</script>