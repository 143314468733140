<template>
    <div class="login-area">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-image">
                    <img src="" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="login-form">
                                <div class="logo">
                                    <router-link to="/">
                                        <img src="" alt="image">
                                    </router-link>
                                </div>

                                <h3>Welcome back</h3>
                                <p>New to Vibion? <router-link to="/register">Register</router-link></p>

                                <form>
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" placeholder="Your email address" class="form-control">
                                    </div>

                                    <div class="form-group">
                                        <input type="password" name="password" id="password" placeholder="Your password" class="form-control">
                                    </div>

                                    <button type="submit" class="btn btn-primary">Login</button>
                                    
                                    <div class="forgot-password">
                                        <router-link to="/register">
                                            Forgot Password?
                                        </router-link>
                                    </div>

                                    <div class="connect-with-social">
                                        <button type="submit" class="facebook"><i class="fab fa-facebook-square"></i> Connect with Facebook</button>
                                        <button type="submit" class="google"><i class="fab fa-google"></i> Connect with Google</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoginArea'
    }
</script>