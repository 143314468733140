<template>
    <div>
        <RegisterForm />
    </div>
</template>

<script>
import RegisterForm from '../Register/RegisterForm'

export default {
    name: 'Register',
    components: {
        RegisterForm
    }
}
</script>