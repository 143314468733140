<template>
    <div class="how-it-works-area ptb-70">
        <div class="container">
            <div class="section-title">
                <h2>Check Out How DOOH Digital Signage Works With <font class="logo">dibasys</font></h2>
                <div class="bar"></div>
                <p>How it works</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img :src="step1" alt="image">

                        <h3>1. Sign up to dibasys account</h3>
                        <p>Your name and email address are all we need. No credit card or any bank details are needed for getting started with us!</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img :src="step2" alt="image">

                        <h3>2. Select the device you want</h3>
                        <p>Take a look at the list of locations where we have our devices installed. Choose the devices you want your ads to run on.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img :src="step3" alt="image">

                        <h3>3. Choose the slots and add to cart</h3>
                        <p>Select as many slots as you want from your chosen devices and add them to the cart.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img :src="step4" alt="image">

                        <h3>4. Do the purchase</h3>
                        <p>With all the slots you have in the cart, click the purchase button and it will take you through our integrated Stripe checkout process to complete your purchase.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img :src="step5" alt="image">

                        <h3>5. Slot is all yours</h3>
                        <p>All purchased slots will now be available on your device list; make use of them for publishing your advertisements.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-how-it-works">
                        <img :src="step6" alt="image">

                        <h3>6. Create your contents now</h3>
                        <p>Just schedule and upload images or videos straight from your PC or smartphone and link with the slots you have.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
    export default {
        name: 'HowItWorks',
         data (){
        return {
            step1: `${cdnUrl}/dibasys/how-it-works-1.png`,
            step2: `${cdnUrl}/dibasys/how-it-works-2.png`,
            step3: `${cdnUrl}/dibasys/how-it-works-3.png`,
            step4: `${cdnUrl}/dibasys/how-it-works-4.png`,
            step5: `${cdnUrl}/dibasys/how-it-works-5.png`,
            step6: `${cdnUrl}/dibasys/how-it-works-6.png`,
        }
    },
    }
</script>