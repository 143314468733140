<template>
    <div class="services-area ptb-70">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-content">
                    <div class="content left-content">
                        <span class="sub-title">Media Library</span>
                        <h2>Media Management & Playlists</h2>
                        <div class="bar"></div>
                        <p>In-built storage for saving your playlists with folder creation and more.</p>

                        <ul class="features-list">
                            <li><span><i class="flaticon-check-mark"></i> Easy access</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Ready to use</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Preview/edit anytime</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Share across your organization</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Multiple uploads</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Filter by tags</span></li>
                        </ul>

                        <router-link to="/register" class="btn btn-primary">
                            Free 30 Day Trial
                        </router-link>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img :src="featuresSub2" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
    export default {
        name: 'ServicesThree',
        data (){
        return {
            featuresSub2: `${cdnUrl}/dibasys/media-files.jpg`,
        }
    },
    }
</script>