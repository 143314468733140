<template>
    <div class="page-title-area item-bg1">
        <div class="container">
            <div class="page-title-content">
                <h2>FAQ</h2>
                <p>Frequently Asked Questions</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>