<template>
    <div>
        <footer class="footer-area">
            <div class="container">
                <div class="row">
                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget">
                            <div class="logo">
                                <router-link to="/"><img :src="footerLogo" alt="logo"></router-link>
                                <p>We pride ourselves to provide industry leading content management suite for digital signages.</p>
                            </div>

                            <ul class="social-links">
                                <li><a href="https://www.facebook.com/" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="https://www.instagram.com/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="https://www.linkedin.com/" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget pl-5">
                            <h3>Company</h3>

                            <ul class="list">
                                <li><router-link to="/about-us">About Us</router-link></li>
                                <li><router-link to="/products">Products</router-link></li>
                                <li><router-link to="/features">Features</router-link></li>
                                <li><router-link to="/pricing">Our Pricing</router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget">
                            <h3>Support</h3>

                            <ul class="list">
                                <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                                <li><router-link to="/contact">Contact Us</router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-sm-6 col-md-6">
                        <div class="single-footer-widget">
                            <h3>Address</h3>

                            <ul class="footer-contact-info">
                                <li><span>Location:</span> Level 1, Nexus Arcade, Elikkulam P.O, Kottayam, Kerala 686577</li>
                                <li><span>Email:</span> <a href="mailto:info@dibasys.com">info@dibasys.com</a></li>
                                <li><span>Phone:</span> <a href="tel:04822230261">0482 2230 261</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="copyright-area">
                    <p>&copy; {{currentYear}} <font class="logo">dibasys. </font> All rights reserved <a href="https://dibasys.in/" target="_blank">dibasys.in</a></p>
                </div>
            </div>

            <div class="map-image"><img src="../../assets/images/map.png" alt="map"></div>
        </footer>
        <!-- End Footer Area -->

        <div 
            @click="scrollToTop()" 
            :class="['back-to-top-btn', {'go-top': isTop}]"
        >
            <i class="fas fa-arrow-up"></i>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
export default {
    name: 'Footer',
    data (){
        return {
            isTop: false,
            currentYear: new Date().getFullYear(),
            footerLogo: `${cdnUrl}/dibasys/footer-dbs-logo.png`,
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
    mounted(){
        const that = this
        window.addEventListener('scroll', () => {
            let scrollPos = window.scrollY
            if(scrollPos >= 100){
                that.isTop = true
            } else {
                that.isTop = false
            }
        })
    }
}
</script>