<template>
    <div class="services-area ptb-70 bg-f7fafd">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-image">
                    <div class="image">
                        <img :src="featuresSub3" alt="image">
                    </div>
                </div>

                <div class="overview-content">
                    <div class="content">
                        <span class="sub-title">We Made It Easy</span>
                        <h2>Content Editor + PosterMyWall</h2>
                        <div class="bar"></div>
                        <p>Create stunning contents within our application using the in-built editor and integrated PosterMyWall plugin we got.</p>

                        <ul class="features-list">
                            <li><span><i class="flaticon-check-mark"></i> Make contents easily</span></li>
                            <li><span><i class="flaticon-check-mark"></i> 1000+ templates </span></li>
                            <li><span><i class="flaticon-check-mark"></i> Unlimited editing</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Easy to use</span></li>
                        </ul>

                        <router-link to="/register" class="btn btn-primary">
                            Learn More
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
    export default {
        name: 'ServicesFour',
        data (){
        return {
            featuresSub3: `${cdnUrl}/dibasys/postermywall.jpg`,
        }
    },
    }
</script>