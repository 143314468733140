<template>
    <div class="services-area ptb-70 bg-f7fafd">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-image">
                    <div class="image">
                        <img :src="featuresSub1" alt="image">
                    </div>
                </div>

                <div class="overview-content">
                    <div class="content">
                        <span class="sub-title">Announce Sales</span>
                        <h2>Tickers (Scrolling Text)</h2>
                        <div class="bar"></div>
                        <p>The text ticker lets you display significant information in a scrolling text box, such as upcoming events, announcements, and more.</p>

                        <ul class="features-list">
                            <li><span><i class="flaticon-check-mark"></i> Convey messages quickly</span></li>
                            <li><span><i class="flaticon-check-mark"></i>Alert customers about offer sales</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Announce upcoming events</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Promote business</span></li>
                        </ul>

                        <router-link to="/register" class="btn btn-primary">
                            Get Started
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
    export default {
        name: 'ServicesTwo',
        data (){
        return {
            featuresSub1: `${cdnUrl}/dibasys/digital-screens.jpg`,
        }
    },
    }
</script>