import { createWebHashHistory, createRouter } from "vue-router";

// All Pages
import DigitalBankingPage from "../components/pages/DigitalBankingPage";
import AboutUsPage from "../components/pages/AboutUsPage";
import HowItWorksPage from "../components/pages/HowItWorksPage";
import FeaturesTwo from "../components/pages/FeaturesTwo";
import Team from "../components/pages/Team";
import Pricing from "../components/pages/Pricing";
import Login from "../components/pages/Login";
import Register from "../components/pages/Register";
import Error from "../components/pages/Error";
import Faq from "../components/pages/Faq";
import BlogTwo from "../components/pages/BlogTwo";
import Contact from "../components/pages/Contact";
import PrivacyPolicy from "../components/pages/PrivacyPolicy";
import TermsCondition from "../components/pages/TermsCondition";
import Products from "../components/pages/Products";

const routes = [
  { path: "/", component: DigitalBankingPage },
  { path: "/about-us", component: AboutUsPage },
  { path: "/how-it-works", component: HowItWorksPage },
  { path: "/features", component: FeaturesTwo },
  { path: "/team", component: Team },
  { path: "/pricing", component: Pricing },
  { path: "/login", component: Login },
  { path: "/register", component: Register },
  { path: "/error", component: Error },
  { path: "/faq", component: Faq },
  { path: "/blog-2", component: BlogTwo },
  { path: "/contact", component: Contact },
  { path: "/privacy-policy", component: PrivacyPolicy },
  { path: "/terms-condition", component: TermsCondition },
  { path: "/products", component: Products },
];

const router = createRouter({
  history: createWebHashHistory(),
  linkExactActiveClass: "active",
  routes,
  scrollBehavior() {
    return { top: 0, behavior: "smooth" };
  },
});

export default router;
