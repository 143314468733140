<template>
    <div>
        <Navbar />
        <PageTitle />
        <Features />
        <ServicesTwo />
        <ServicesThree />
        <ServicesFour />
        <FeatureTwo />
        <AccountCreate />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../layout/Navbar'
import PageTitle from '../features-two/PageTitle'
import Features from '../features-two/Features'
import ServicesTwo from '../features-two/ServicesTwo'
import ServicesThree from '../features-two/ServicesThree'
import ServicesFour from '../features-two/ServicesFour'
import FeatureTwo from '../features-two/FeatureTwo'
import AccountCreate from '../common/AccountCreate'
import Footer from '../layout/Footer'

export default {
    name: 'FeaturesTwo',
    components: {
        Navbar,
        PageTitle,
        Features,
        ServicesTwo,
        ServicesThree,
        ServicesFour,
        FeatureTwo,
        AccountCreate,
        Footer,
    }
}
</script>