<template>
    <div class="payment-features-area ptb-70">
        <div class="container">
            <div class="payment-features-overview">
                <div class="payment-features-image">
                    <div class="image">
                        <img :src="homeSub2" alt="image">
                    </div>
                </div>

                <div class="payment-features-content">
                    <div class="content">
                        <h2>Raise your brand to new heights and stand out in 3 simple steps</h2>
                        <p><span class="step-header">Log in to Your DBS Account</span> <br>Our simple cloud-based digital signage software enables you to access your account online from your computer or smartphone. No matter where you are, make changes to the advertisements or your device slots.</p>
                        <p><span class="step-header">Upload Your Media</span> <br>Just drag and drop the images or videos from your computer or select them from the playlist you have created in our media library, schedule and link your advertisements to the device slots you got, and finally click the "Create" button. Your ad will be saved under the list and is ready to go live now!</p>
                        <p><span class="step-header">Hit the "Publish" Button</span> <br>Get your ads on your device slots by simply hitting the publish button on your left navigation drawer. And remember, no matter where you are and where your screens are, create, schedule, and publish on the go!</p>
                        <br>
                        <router-link to="/register" class="btn btn-primary">
                            Sign Up Now
                        </router-link>
                    </div>
                </div>
            </div>

            <div class="services-area ptb-70">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-content">
                    <div class="content left-content">
                        <h2>Get started for free today</h2>
                        <p>Explore the realm of DOOH by joining us free today.</p>
                        <div class="bar"></div>

                        <ul class="services-list">
                            <li><span><i class="flaticon-check-mark"></i> Start your free 30 day trial</span></li>
                            <li><span><i class="flaticon-check-mark"></i> No credit card required</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Instant access to DOOH campaign tools</span></li>
                            <li><span><i class="flaticon-check-mark"></i>Choose any desired location to display your ads</span></li>
                        </ul>
                        <router-link to="/register" class="btn btn-primary">
                            Sign Up Now
                        </router-link>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img :src="homeSub3" alt="image">

                        <div class="circle-img">
                            <img :src="circle" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
    export default {
        name: 'Payment',
        data (){
        return {
            homeSub3: `${cdnUrl}/dibasys/businesses-2.png`,
            circle: `${cdnUrl}/dibasys/circle.png`,
            homeSub2: `${cdnUrl}/dibasys/payment-features2.jpg`,
        }
    },
        
    }
</script>