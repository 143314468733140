<template>
    <div>
        <NavbarStyleTwo />
        <MainBanner />
        <Services />
        <ServicesThree />
        <div class="currency-transfer-provider-with-background-color">
        <WhyChoose />
        </div>
        <br>
        <br>
        <br>
        <FunFacts />
        <div class="currency-transfer-provider-with-background-color">
        <ReadyToTalk />
        <Partner />
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <Feedback />
        <br>
        </div>
        <AccountCreate />
        <Footer />
    </div>
</template>

<script>
import NavbarStyleTwo from '../layout/NavbarStyleTwo'
import MainBanner from '../digital-banking/MainBanner'
import Services from '../common/Services'
import ServicesThree from '../common/ServicesThree' 
import FunFacts from '../common/FunFacts'
import Feedback from '../common/Feedback'
import ReadyToTalk from '../common/ReadyToTalk'
import Partner from '../common/Partner'
import AccountCreate from '../common/AccountCreate'
import Footer from '../layout/Footer'
import WhyChoose from '../common/WhyChoose.vue'

export default {
    name: 'DigitalBankingPage',
    components: {
        NavbarStyleTwo,
        MainBanner,
        Services,
        ServicesThree,
        FunFacts,
        Feedback,
        ReadyToTalk,
        Partner,
        AccountCreate,
        Footer,
        WhyChoose,
    }
}
</script>