<template>
    <div>
        <Navbar />
        <PageTitle />
        <About />
        <AccountCreate />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../layout/Navbar'
import PageTitle from '../about-us/PageTitle'
import About from '../about-us/About'
import AccountCreate from '../common/AccountCreate'
import Footer from '../layout/Footer'

export default {
    name: 'AboutUs',
    components: {
        Navbar,
        PageTitle,
        About,
        AccountCreate,
        Footer,
    }
}
</script>