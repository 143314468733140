<template>
    <div class="pricing-area ptb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>40" Inch LED Screen</h3>
                            <h3>Foot Traffic More Than 25k</h3>
                            <p>Get your business up by running a content in it's 10 Sec Slot!</p>
                        </div>
                        <br>
                        <div class="buy-btn">
                            <router-link to="/register" class="btn btn-primary">
                                Contact Now
                            </router-link>
                        </div>

                        <ul class="pricing-features">
                            <li><i class="fas fa-check"></i> Advertise with advanced dayparting</li>
                            <li><i class="fas fa-check"></i> Calendar view of schedules</li>
                            <li><i class="fas fa-check"></i> Media library</li>
                            <li><i class="fas fa-check"></i> Integrated content editor</li>
                            <li><i class="fas fa-check"></i> Device activity timeline</li>
                            <li><i class="fas fa-check"></i> Many more...</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>60" Inch LED Screen</h3>
                            <h3>Foot Traffic More Than 40k</h3>
                            <p>Get your business up by running a content in it's 10 Sec Slot!</p>
                        </div>

                        <br>
                        <div class="buy-btn">
                            <router-link to="/register" class="btn btn-primary">
                                Contact Now
                            </router-link>
                        </div>

                        <ul class="pricing-features">
                            <li><i class="fas fa-check"></i> Advertise with advanced dayparting</li>
                            <li><i class="fas fa-check"></i> Calendar view of schedules</li>
                            <li><i class="fas fa-check"></i> Media library</li>
                            <li><i class="fas fa-check"></i> Integrated content editor</li>
                            <li><i class="fas fa-check"></i> Device activity timeline</li>
                            <li><i class="fas fa-check"></i> Many more...</li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                    <div class="single-pricing-box">
                        <div class="pricing-header">
                            <h3>80" Inch LED Screen</h3>
                            <h3>Foot Traffic More Than 50k</h3>
                            <p>Get your business up by running a content in it's 10 Sec Slot!</p>
                        </div>

                        <br>
                        <div class="buy-btn">
                            <router-link to="/register" class="btn btn-primary">
                                Contact Now
                            </router-link>
                        </div>

                        <ul class="pricing-features">
                            <li><i class="fas fa-check"></i> Advertise with advanced dayparting</li>
                            <li><i class="fas fa-check"></i> Calendar view of schedules</li>
                            <li><i class="fas fa-check"></i> Media library</li>
                            <li><i class="fas fa-check"></i> Integrated content editor</li>
                            <li><i class="fas fa-check"></i> Device activity timeline</li>
                            <li><i class="fas fa-check"></i> Many more...</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PricingArea'
    }
</script>