<template>
    <div class="login-area">
        <div class="row m-0">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-image">
                    <img :src="registerBg" alt="image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="login-content">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <div class="login-form">
                                <div class="logo">
                                    <router-link to="/">
                                        <img width="300" height="150" :src="dbsLogo" alt="image">
                                    </router-link>
                                </div>

                                <h3>Contact Us</h3>
                                <p>Already have an account? <router-link @click="toLoginPage()" to="/">Log In</router-link></p>

                                <form>
                                    <div class="form-group">
                                       <input type="text" name="first_name" id="first_name" class="form-control" required data-error="Please enter your name" placeholder="First Name">
                                    </div>

                                    <div class="form-group">
                                        <input type="text" name="last_name" id="last_name" class="form-control" required data-error="Please enter your name" placeholder="Last Name">
                                    </div>

                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required data-error="Please enter your number" class="form-control" placeholder="Phone">
                                    </div>
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required data-error="Please enter your name" placeholder="Email">
                                    </div>
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" required data-error="Write your message" placeholder="Your Message"></textarea>
                                    </div>

                                    <button type="submit" class="btn btn-primary">Send Message</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
    export default {
        name: 'RegisterForm',
         data (){
        return {
            registerBg: `${cdnUrl}/dibasys/registr-bg1.jpg`,
            dbsLogo: `${cdnUrl}/dibasys/dbs-logo.png`,
        }
    },
        methods: {
            toLoginPage() {
                window.open('https://dooh.dibasys.com/', '_blank');
            },
        }
    }
</script>