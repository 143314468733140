<template>
    <div class="features-area ptb-70 bg-f6f4f8" style="background-color: #F4F7F9;">
        <div class="container">
            <div class="section-title">
                <h2>What we offers</h2>
                <div class="bar"></div>
                <p>Dibasys is a feature-rich DOOH signage solution that reliably produces professional results. We strive to deliver features that are extremely simple to use while remaining affordable.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-settings"></i>
                        </div>

                        <h3>Schedule Advertisements with Advanced Dayparting</h3>
                        <p>Schedule and run your advertisements on different timings of a day or specific days of a week.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-c679e3">
                            <i class="flaticon-info"></i>
                        </div>

                        <h3>Easy Screen Grouping & Management</h3>
                        <p>Our powerful hierarchical screen grouping allows you to easily manage multiple devices.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-cdf1d8">
                            <i class="flaticon-menu"></i>
                        </div>

                        <h3>Chart Display of Advertisements Status</h3>
                        <p>Dashboard features various graphs and cards for displaying the active, scheduled and expired advertisements counts.</p>
                    </div>
                </div>


                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-eb6b3d">
                            <i class="flaticon-cursor"></i>
                        </div>

                        <h3>Calendar View of Schedules</h3>
                        <p>Calendar view gives you the granularity of which advertisement is playing where.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon bg-f78acb">
                            <i class="flaticon-envelope-of-white-paper"></i>
                        </div>

                        <h3>Reports & Analytics</h3>
                        <p>Examine your digital screen management and analyze your business growth using reports and analytics.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-features-box">
                        <div class="icon">
                            <i class="flaticon-alarm"></i>
                        </div>

                        <h3>Notifications</h3>
                        <p>You will be timely notified about your device offline, publish statuses and many more..</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Features'
    }
</script>