<template>
    <div class="preloader">
        <div class="loader">
            <div class="shadow"></div>
            <div class="box"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Preloader'
    }
</script>