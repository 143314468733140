<template>
    <div class="ctp-currency-area ptb-100">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-image"><img :src="productsSub" alt=""></div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="ctp-currency-content">
                        <h3>DOOH Advertisements</h3>
                        <p>Dibasys - DOOH ( DiDOOH ) offers brands and partners a plethora  of opportunities to maximise their advertisement 
                            reach with advanced campaign tools creating lasting impression in the minds of customers  on their purchase journey.
                        </p>
                        <ul class="list">
                            <li> 
                               <i class="flaticon-check-mark"></i>
                                Drives brand awarness and brand recall
                            </li>
                            <li>
                                <i class="flaticon-check-mark"></i>
                                Gives brands a contemporary face
                            </li>
                            <li>
                                <i class="flaticon-check-mark"></i>
                                Influence the customers at the point of purchase
                            </li>
                            <li>
                                <i class="flaticon-check-mark"></i>
                                Aids to build high margin revenue
                            </li>
                        </ul>
                        <div class="currency-btn">
                            <router-link to="/register" class="btn btn-primary">Start for Free</router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
export default {
    name: 'BusinessCurrency',
    data (){
        return {
            productsSub: `${cdnUrl}/dibasys/dooh-screen1.jpg`,
        }
    },
}
</script>