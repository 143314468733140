<template>
    <div class="page-title-area item-bg1">
        <div class="container">
            <div class="page-title-content">
                <h2>DOOH Signage</h2>
                <p>Advertise Digitally</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PageTitle'
}
</script>