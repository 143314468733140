<template>
    <div class="ctp-reviews-area pt-100 pb-75">
        <div class="container">
            <div class="section-title ctp-title">
                <h2>Ratings And Reviews</h2>
            </div>

            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="ctp-reviews-box">
                        <ul class="rating">
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <span>Reviews 15</span>
                            </li>
                        </ul>
                        <h3>Great exchange rates</h3>
                        <p>
                            Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Donec rutrum leo eget malesuada.
                        </p>
                        <h4>Alisha Ingram <span>(CEO & Founder)</span></h4>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="ctp-reviews-box">
                        <ul class="rating">
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <span>Reviews 10</span>
                            </li>
                        </ul>
                        <h3>Safe and secure</h3>
                        <p>
                            Proin eget tortor risus. Cras ultricies ligula sed magna dictum porta. lorem ipsum dolor sit amet, consectetur adipiscing elit. mauris blandit aliquet elit.
                        </p>
                        <h4>Sharon Bass <span>(Manager)</span></h4>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="ctp-reviews-box">
                        <ul class="rating">
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <img src="../../assets/images/currency-transfer-provider/star.svg" alt="image">
                            </li>
                            <li>
                                <span>Reviews 16</span>
                            </li>
                        </ul>
                        <h3>Award-winning service</h3>
                        <p>
                            Praesent sapien massa, convallis a pellentesque nec, egestas non nisi. praesent sapien massa, convallis a pellentesque nec, egestas non nisi. proin eget tortor risus.
                        </p>
                        <h4>Nancy Artiaga <span>(Founder at Luvion)</span></h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Reviews'
}
</script>