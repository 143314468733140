<template>
    <div>
        <div class="banner-section">
            <div class="container">
                <div class="row m-0 align-items-center">
                    <div class="col-lg-5 col-md-12 p-0">
                        <div class="hero-content">
                            <h1>Reach Your Ideal Customer at Right Place and Right Time</h1>
                            <h2>Showcase Your Brand In Large Digital Canvas.</h2>
                            <p>Connect with audience in a dynamic and engaging way using Dibasys - DOOH.</p>
                            <router-link to="/register" class="btn btn-primary">
                                Free 30 Day Trial
                            </router-link>
                        </div>
                    </div>

                    <div class="col-lg-7 col-md-12 p-0">
                        <div class="hero-image">
                            <div class="main-image">
                                <video :src="homeVideo1" muted loop autoplay />
                            </div>

                            <div class="main-mobile-image">
                                <video :src="homeVideo1" muted loop autoplay />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
export default {
    name: 'MainBanner',
    data (){
        return {
            isPopup: false,
            homeVideo1: `${cdnUrl}/dibasys/home page.mp4`,
        }
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
    },
}
</script>