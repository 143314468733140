<template>
    <div class="services-area ptb-70">
        <div class="container-fluid p-0">
            <div class="overview-box">
                <div class="overview-content">
                    <div class="content left-content">
                        <h2>What is DOOH advertising ?</h2>
                        <div class="bar"></div>
                        <p>It refers to displaying dynamic ads, campaigns or promotional videos on large screens known as 'Digital Screens' or 'Digital Signage' to 
                            captivate audience in real time. Primarily located in high traffic areas such as transits, malls, retails, entertainment venues, streets 
                            and places of public gathering, DOOH advertising is emerging as a powerful method to effectively communicate with targeted audience and offers immense benefits including
                        </p>

                        <ul class="services-list">
                            <li><span><i class="flaticon-check-mark"></i> Visually stunning displays</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Amplify brand name</span></li>
                            <li><span><i class="flaticon-check-mark"></i> Tremendous outreach</span></li>
                            <li><span><i class="flaticon-check-mark"></i>Foster Sales and revenue growth</span></li>
                        </ul>
                    </div>
                </div>

                <div class="overview-image">
                    <div class="image">
                        <img :src="doohAd" alt="image">

                        <div class="circle-img">
                            <img :src="circle" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cdnUrl } from '../../app-globals.js';
    export default {
        name: 'Services',
        data (){
        return {
            doohAd: `${cdnUrl}/dibasys/businesses-1.png`,
            circle: `${cdnUrl}/dibasys/circle.png`,
        }
    },
    }
</script>