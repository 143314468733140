<template>
    <div class="page-title-area item-bg2">
        <div class="container">
            <div class="page-title-content">
                <h2>Contact</h2>
                <p>If you have an idea, we would love to hear about it.</p>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>