<template>
    <div>
        <LoginArea />
    </div>
</template>

<script>
import LoginArea from '../log-in/LoginArea'

export default {
    name: 'Login',
    components: {
        LoginArea
    }
}
</script>